import { ArrowRight, ChevronRight, IconArrowRight } from '@/components/icons'
import { useAuth } from '@/lib/auth'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { motion } from 'framer-motion'

export default function IndexPage() {
  const auth = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (auth.user) {
      router.push('/home')
    }
  }, [auth.user])

  const signIn = async () => {
    await auth.signin()
    router.push('/home')
  }

  return (
    <div className="bg-white w-screen h-screen relative font-landing">
      <div className="login-box-container z-10 flex items-center absolute inset-0">
        <div className="login-box ml-20 w-[368px] bg-white rounded-2xl text-[#151E30] text-sm py-12 px-10">
          <img src="/logo.svg" />
          <div className="text-2xl pt-2">
            <span className="font-semibold">worklens</span>{' '}
            <span className="opacity-30">alpha</span>
          </div>
          <div className="pt-3.5">
            <span className="opacity-75">
              WorkLens is currently is currently available on an invite-only
              basis.
            </span>{' '}
            <a
              href="https://www.worklens.com/"
              className="inline-flex items-end"
            >
              <div>Learn more</div>
              <ChevronRight opacity={1} />
            </a>
          </div>
          <div className="mt-8">
            <motion.button
              whileHover="hover"
              whileTap="tap"
              variants={{
                hover: { backgroundColor: '#F0F0F0' },
                tap: { backgroundColor: '#EBEBEB' },
              }}
              className="bg-white rounded-md -ml-2 w-56 p-2 flex justify-between items-center cursor-pointer"
              onClick={signIn}
            >
              <div className="flex">
                <img src="/login/logo-apple.svg" />
                <div className="ml-1.5">Continue with Apple</div>
              </div>
              <motion.div
                className="opacity-30"
                variants={{
                  hover: { opacity: 1 },
                  tap: { opacity: 1 },
                }}
              >
                <IconArrowRight />
              </motion.div>
            </motion.button>
            <motion.button
              whileHover="hover"
              whileTap="tap"
              variants={{
                hover: { backgroundColor: '#F0F0F0' },
                tap: { backgroundColor: '#EBEBEB' },
              }}
              className="bg-white mt-1 rounded-md -ml-2 w-56 p-2 flex justify-between items-center cursor-pointer"
              onClick={signIn}
            >
              <div className="flex">
                <img src="/login/logo-google.svg" />
                <div className="ml-1.5">Continue with Google</div>
              </div>
              <motion.div
                className="opacity-30"
                variants={{
                  hover: { opacity: 1 },
                  tap: { opacity: 1 },
                }}
              >
                <IconArrowRight />
              </motion.div>
            </motion.button>
          </div>
          <div className="mt-20 login-box-footer">
            <a href="#" className="block login-box-footer-privacy">
              Safety and Privacy
            </a>
            <a
              href="https://playground.ai/"
              className="opacity-30 block login-box-footer-copyright"
            >
              © 2022 Playground Technologies Ltd.
            </a>
          </div>
        </div>
      </div>
      <img
        className="bg-picture h-full w-auto absolute right-0 max-w-none"
        src="/login/image-loginpage-wide.jpg"
      />
      <img
        className="bg-wave-shape h-full w-auto absolute right-0 max-w-none "
        src="/login/shape-home.svg"
      />
      <style jsx>{`
         {
          /* Aspect ratio of svg shape*/
        }
        @media (min-aspect-ratio: 1014/798) {
          .bg-wave-shape {
            left: 0;
          }
        }

        @media (max-width: 1100px) {
          .login-box {
            box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.08);
          }
        }

        @media (min-width: 1200px) {
          .login-box-footer-privacy {
            position: absolute;
            bottom: 56px;
          }

          .login-box-footer-copyright {
            position: absolute;
            bottom: 56px;
            right: 120px;
            color: white;
            opacity: 1;
          }
        }

        @media (max-width: 540px) {
          .login-box {
            margin-left: 0;
            box-shadow: none;
          }

          .login-box-container {
            justify-content: center;
            align-items: flex-start;
          }

          .bg-picture,
          .bg-wave-shape {
            height: 400px;
            bottom: 0;
          }

          .bg-wave-shape {
            left: -250px;
          }

          .login-box-footer {
            position: absolute;
            bottom: 15px;
            color: white;
          }

          .login-box-footer-copyright {
            margin-top: 5px;
            opacity: 1;
          }
        }
      `}</style>
    </div>
  )
}
